require('../styles/view-all-questions.scss');

$(document).on('click', '#search-do', function(){
    var $formData = new FormData();

    $formData.append('categories', $('#category-list').val());
    $formData.append('text', $('#search-text').val());
    $formData.append('date-from', $('#search-date-from').val());
    $formData.append('date-to', $('#search-date-to').val());

    $.ajax({
	type: "POST",
	url: route_searchQuestions,
	data: $formData,
	contentType: false,
	processData: false,
	error: function (jqXHR, exception) {
	},
    }).done(function (data) {
	if(data.status == true){
	    $('#questionsTable').DataTable().clear();
	    $('#questionsTable').DataTable().destroy(); 
	    $('#questionsTable').html(data.questions_table_html);
	    $("#questionsTable").dataTable({
                "searching": false,
                "paging":   true,
                "ordering": true,
                "info":     true,
                "language": language_HR,
                "pageLength": 50,
                "order": [[ 0, "desc" ]],
	    });
	}
    });
    
    return false;
});

$(document).on('click', '#search-cancel', function(){
    $('#category-list').val(null).trigger('change');
    $('#search-text').val('');
    $('#search-date-from').val('');
    $('#search-date-to').val('');
    
    return false;
});

$(document).ready(function() {
    let branch_all = [];
    
    function arr_diff(a1, a2) {
        var a = [], diff = [];
        for (var i = 0; i < a1.length; i++) {
            a[a1[i]] = true;
        }
        for (var i = 0; i < a2.length; i++) {
            if (a[a2[i]]) {
                delete a[a2[i]];
            } else {
                a[a2[i]] = true;
            }
        }
        for (var k in a) {
            diff.push(k);
        }
        return diff;
    }
    
    let optionSelect2 = {
        closeOnSelect: false,
        width: '100%'
    };
    

    if($("select#cpp-category-list").length > 0){
        
	let $select2 = $("#cpp-category-list").select2(optionSelect2);

	var scrollTop;

	$select2.on("select2:selecting", function( event ){
	    var $pr = $( '#'+event.params.args.data._resultId ).parent();
	    scrollTop = $pr.prop('scrollTop');
	});

	$select2.on("select2:select", function( event ){
	    $(window).scroll();

	    var $pr = $( '#'+event.params.data._resultId ).parent();
	    $pr.prop('scrollTop', scrollTop );

	    $(this).val().map(function(index) {
		$("#state"+index).prop('checked', true);
	    });
	});

	$select2.on("select2:unselecting", function ( event ) {
	    var $pr = $( '#'+event.params.args.data._resultId ).parent();
	    scrollTop = $pr.prop('scrollTop');
	});

	$select2.on("select2:unselect", function ( event ) {
	    $(window).scroll();

	    var $pr = $( '#'+event.params.data._resultId ).parent();
	    $pr.prop('scrollTop', scrollTop );

	    var branch	=	$(this).val() ? $(this).val() : [];
	    var branch_diff = arr_diff(branch_all, branch);
	    branch_diff.map(function(index) {
		$("#state"+index).prop('checked', false);
	    });
	});
        
    }
    
    
    
    if($("select#category-list.multiple").length > 0){
	
	let $select2 = $("#category-list").select2(optionSelect2);

	var scrollTop;

	$select2.on("select2:selecting", function( event ){
	    var $pr = $( '#'+event.params.args.data._resultId ).parent();
	    scrollTop = $pr.prop('scrollTop');
	});

	$select2.on("select2:select", function( event ){
	    $(window).scroll();

	    var $pr = $( '#'+event.params.data._resultId ).parent();
	    $pr.prop('scrollTop', scrollTop );

	    $(this).val().map(function(index) {
		$("#state"+index).prop('checked', true);
	    });
	});

	$select2.on("select2:unselecting", function ( event ) {
	    var $pr = $( '#'+event.params.args.data._resultId ).parent();
	    scrollTop = $pr.prop('scrollTop');
	});

	$select2.on("select2:unselect", function ( event ) {
	    $(window).scroll();

	    var $pr = $( '#'+event.params.data._resultId ).parent();
	    $pr.prop('scrollTop', scrollTop );

	    var branch	=	$(this).val() ? $(this).val() : [];
	    var branch_diff = arr_diff(branch_all, branch);
	    branch_diff.map(function(index) {
		$("#state"+index).prop('checked', false);
	    });
	});
	
    }
    
    
    /*
    let branch_all3 = [];
    
    let optionSelect3 = {
        closeOnSelect: false,
        width: '100%'
    };
    
    let $select3 = $("#tag-list").select2(optionSelect3);
    
    var scrollTop;
    
    $select3.on("select2:selecting", function( event ){
        var $pr = $( '#'+event.params.args.data._resultId ).parent();
        scrollTop = $pr.prop('scrollTop');
    });
    
    $select3.on("select2:select", function( event ){
        $(window).scroll();
        
        var $pr = $( '#'+event.params.data._resultId ).parent();
        $pr.prop('scrollTop', scrollTop );
	
        $(this).val().map(function(index) {
            $("#state"+index).prop('checked', true);
        });
    });
    
    $select3.on("select2:unselecting", function ( event ) {
        var $pr = $( '#'+event.params.args.data._resultId ).parent();
        scrollTop = $pr.prop('scrollTop');
    });
    
    $select3.on("select2:unselect", function ( event ) {
        $(window).scroll();
        
        var $pr = $( '#'+event.params.data._resultId ).parent();
        $pr.prop('scrollTop', scrollTop );
        
        var branch	=	$(this).val() ? $(this).val() : [];
        var branch_diff = arr_diff(branch_all3, branch);
        branch_diff.map(function(index) {
            $("#state"+index).prop('checked', false);
        });
    });
    */
    
});

$('#questionDetailsModal').on('show.bs.modal', function (e) {
    var button = $(e.relatedTarget);
    $.ajax({
	type: "GET",
	cache: false,
	url: route_getQuestionDetails
		.replace('idPlaceholder', button.data('id')),
	error: function (jqXHR, exception) {
	},
    }).done(function (data) {
	
	$('#q-details-id').html(data.id);
	$('#q-details-published').html(data.published);
	$('#q-details-question').html(data.question);
	$('#q-details-answer').html(data.answer);
	if(data.a1 || data.a2 || data.a3){
	    var l_html = '';
	    if(data.a1){
		l_html += '<button type="button" class="btn-sm btn-light list-group-item list-group-item-action download_file" data-id="' + data.id + '" data-file="' + data.a1 + '"><i class="fas fa-file-pdf"></i> ' + data.a1 + '</button>';
	    }
	    if(data.a2){
		l_html += '<button type="button" class="btn-sm btn-light list-group-item list-group-item-action download_file" data-id="' + data.id + '" data-file="' + data.a2 + '"><i class="fas fa-file-pdf"></i> ' + data.a2 + '</button>';
	    }
	    if(data.a3){
		l_html += '<button type="button" class="btn-sm btn-light list-group-item list-group-item-action download_file" data-id="' + data.id + '" data-file="' + data.a3 + '"><i class="fas fa-file-pdf"></i> ' + data.a3 + '</button>';
	    }
	    
	    $('#q-details-attachments-files').html(l_html);
	    $('#q-details-attachments').show();
	}
    });
});

$('#questionDetailsModal').on('hide.bs.modal', function (e) {
    $('#q-details-attachments').hide();
    $('#q-details-attachments-files').html('');
});

$(document).ready(function () {
    
    $("#questionsTable").dataTable({
        "searching": false,
        "paging":   true,
        "ordering": true,
        "info":     true,
        "language": language_HR,
        "pageLength": 50,
        "order": [[ 0, "desc" ]],
    });
    
});   

$(document).on('click', '.download_file', function(){
    location.href = route_openFile
	    .replace('idPlaceholder', $(this).data('id'))
	    .replace('filePlaceholder', $(this).data('file'));
    return false;
});


$(document).on('click', '#dashboard-cpp-search-clear', function(){
    if($("select#cpp-category-list").length > 0){
	$('#cpp-category-list').val(null).trigger("change");
    }
    $('#cpp-content').val('');
    return false;
});


$(document).on('change', '#category-list.tender', function(){
    
    var l_value = $(this).val() ? $(this).val() : 0;
    
    $.ajax({
	type: "GET",
	cache: false,
	url: route_getCategoriesTender
		.replace('idPlaceholder', l_value),
	error: function (jqXHR, exception) {
	},
    }).done(function (data) {
        
        l_value ?
            $('#category-area-list.tender').removeAttr('disabled')
            :
            $('#category-area-list.tender').prop('disabled', 'disabled');
        
        $('#category-area-list.tender option').remove();
        $('#category-area-list.tender').append($('<option>').val('').text('--'));
        for(var i = 0; i < data.length; i++){
            $('#category-area-list.tender').append($('<option>').val(data[i].id).text(data[i].name));
        }
    });
    
    return false;
});

$(document).on('click', '#dashboard-cpp-search', function(){

    var $formData = new FormData();

    $formData.append('categories', $('#cpp-category-list').val());
    $formData.append('content', $('#cpp-content').val());
    $formData.append('mode', 2);

    $.ajax({
	type: "POST",
	url: route_getCppQuestionsBySearch,
	data: $formData,
	contentType: false,
	processData: false,
	error: function (jqXHR, exception) {
	},
    }).done(function (data) {
	$('#cppQuestionsTable').DataTable().clear();
	$('#cppQuestionsTable').DataTable().destroy(); 
	$('#cppQuestionsTable').html(data.data_table_body_html);
	$("#cppQuestionsTable").dataTable({
            "searching": false,
            "paging":   true,
            "ordering": true,
            "info":     true,
            "language": language_HR,
            "pageLength": 50,
            "order": [[ 0, "desc" ]],
	});
    });

    return false;
});

$(document).ready(function () {
    $("#cppQuestionsTable").dataTable({
        "searching": false,
        "paging":   true,
        "ordering": true,
        "info":     true,
        "language": language_HR,
        "pageLength": 50,
        "order": [[ 0, "desc" ]],
    });
});   


$(document).on('click', '#dashboard-search-clear', function(){
    if($("select#category-list").length > 0){
	$('#category-list').val(null).trigger("change");
    }
    $('#tag-list').val(null).trigger("change");
    $('#status-list').val(-1);
    $('#content').val('');
    $('#date-from').val('');
    $('#date-to').val('');
    return false;
});

$(document).on('click', '#dashboard-search', function(){

    var $formData = new FormData();

    $formData.append(
        'categories',
        ($('#category_type_id').length && $('#category_type_id').val() == 2) ?
            $('#category-area-list').val()
            :
            $('#category-list').val()
    );
    $formData.append('tags', $('#tag-list').val());
    $formData.append('status', $('#status-list').val());
    $formData.append('content', $('#content').val());
    $formData.append('date-from', $('#date-from').val());
    $formData.append('date-to', $('#date-to').val());
    $formData.append('mode', 2);
    $formData.append('category_type_id', $('#category_type_id').length ? $('#category_type_id').val() : '');

    $.ajax({
	type: "POST",
	url: route_getQuestionsBySearch,
	data: $formData,
	contentType: false,
	processData: false,
	error: function (jqXHR, exception) {
	},
    }).done(function (data) {
	$('#questionsTable').DataTable().clear();
	$('#questionsTable').DataTable().destroy(); 
	$('#questionsTable').html(data.data_table_body_html);
	$("#questionsTable").dataTable({
            "searching": false,
            "paging":   true,
            "ordering": true,
            "info":     true,
            "language": language_HR,
            "pageLength": 50,
            "order": [[ 0, "desc" ]],
	});
    });

    return false;
});

/* Print Modal */
$().ready(function () {
    $('.modal.printable').on('shown.bs.modal', function () {
        $('.modal-dialog', this).addClass('focused');
        $('body').addClass('modalprinter');

        if ($(this).hasClass('autoprint')) {
            window.print();
        }
    }).on('hidden.bs.modal', function () {
        $('.modal-dialog', this).removeClass('focused');
        $('body').removeClass('modalprinter');
    });
});


// Change Dark/Light
$( ".change" ).on("click", function() {
    if( $( "body" ).hasClass( "dark" )) {
        $( "body" ).removeClass( "dark" );
    } else {
        $( "body" ).addClass( "dark" );
    }
});


        // Font size
        window.addEventListener('load', () => {
            function ObterTamanhoFonte(seletorComClasseAcessibilidade) {
            let tamanho = window.getComputedStyle(seletorComClasseAcessibilidade, null)
                        .getPropertyValue('font-size');
            return parseFloat(tamanho);
        }

        function ControlaTamanhoElementos(seletoresComClasseAcessibilidade, aumentar, normal) {
            for (let i = 0; i < seletoresComClasseAcessibilidade.length; i++) {
                const element = seletoresComClasseAcessibilidade[i];      
                let fontSizeAtual = 0;

                if (normal) {
                    element.style.fontSize = '1em';
                } else {        
                    if (aumentar)
                        fontSizeAtual = ObterTamanhoFonte(element) + 1;
                    else
                        fontSizeAtual = ObterTamanhoFonte(element) - 1;
                    element.style.fontSize = fontSizeAtual.toString() + 'px'; 
                }
            } 
        }

        document.querySelector('#increase').addEventListener('click', e => {
            let acessibilidade = document.getElementsByClassName('accessibility');
            ControlaTamanhoElementos(acessibilidade, true);
        });

        document.querySelector('#normal').addEventListener('click', e => {
            let acessibilidade = document.getElementsByClassName('accessibility');
            ControlaTamanhoElementos(acessibilidade, null, true);
        });

    });